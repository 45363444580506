import axios from 'axios';
import {
  axios as api,
  setAuthorization,
  removeAuthorization,
} from 'utils/axios';
import { profileOptions } from 'utils/constants';
import { signOut } from 'next-auth/react';

const login = async (email, password, userType) => {
  try {
    removeAuthorization();
    const response = await api.post(
      `/dj-rest-auth/login/?userType=${userType}`,
      {
        username: email.toLowerCase(),
        email: email.toLowerCase(),
        password,
        user_type: userType,
      },
    );
    if (response.status == 200) {
      const token = response.data.key;
      setAuthorization(token);
      return response.data;
    }
  } catch (error) {
    let message = 'Error connecting to the server';
    try {
      message = error.response.data['non_field_errors'][0];
    } catch {
      console.error(error);
    }
    throw new Error(message);
  }
};

const logout = async () => {
  try {
    await api.post(`/dj-rest-auth/logout/`);
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    const resp = await signOut({ redirect: false, callbackUrl: '/' });
    window?.analytics?.reset();
    removeAuthorization();
    return resp.url;
  }
};

const loginOrRegisterUser = async (provider, credentials) => {
  const action = credentials?.action || 'login';
  const url = `/${action}/?provider=${provider}`
  try {
    removeAuthorization();
    const response = await api.post(url, credentials);
    if (response.status == 200) {
      const token = response.data.key;
      setAuthorization(token);
    }
    return response.data;
  } catch (error) {
    /**
     * Due to this response goes throug next-auth we are unable to send objects
     * Only strings are allowed.
     * */
    const data = error.response.data;
    try {
      for (const d in data) {
        data[d] = data[d][0]; // convert this: {"key": ["value"]} into this: {"key": "value"}
      }
    } catch {
      console.error(error);
    }
    console.error(`Error performing '${url}'. Error response: '${JSON.stringify(data)}'`)
    throw new Error(JSON.stringify(data));
  }
};

const resendVerificationEmail = async (email) => {
  try {
    const response = await api.post(
      `/dj-rest-auth/registration/resend-email/ `,
      {
        email,
      },
    );
    return response.data;
  } catch (error) {
    let message = 'Error connecting to the server';
    try {
      message = error.response.data['non_field_errors'][0];
    } catch {
      console.error(error);
    }
    throw new Error(message);
  }
};

const makeGetRequest = async (endpoint, token) => {
  const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const response = await axios.get(`${baseURL}${endpoint}`, {
      headers: { Authorization: `Token ${token}` },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const getUserData = async (token) => {
  const response = await makeGetRequest('/user/me/', token);
  return response;
};

const getFirstConversationId = async (token) => {
  const response = await makeGetRequest('/conversations/first_conversation_id/', token);
  return response.data?.id;
};

const updateUserSettings = async (userId, token, data) => {
  const { app_view } = data;
  const appViewUpper = app_view ? app_view.toUpperCase() : null;

  if (appViewUpper && !profileOptions.includes(appViewUpper)) return;

  const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
  const response = await axios.patch(
    `${baseURL}/settings/${userId}/`,
    { ...data, app_view: appViewUpper },
    { headers: { Authorization: `Token ${token}` } },
  );

  if (response.status === 200) {
    return response.data;
  }
};

const completeSignup = async (userId, token, data) => {
  const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;

  const response = await axios.post(
    `${baseURL}/signup/${userId}/complete/`,
    data,
    { headers: { Authorization: `Token ${token}` } },
  );

  if (response.status === 200) {
    return response.data;
  }
};


export {
  login,
  logout,
  loginOrRegisterUser,
  resendVerificationEmail,
  getUserData,
  updateUserSettings,
  completeSignup,
  getFirstConversationId,
};
