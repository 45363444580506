import React from 'react';
import { Box, Container, Link, Typography, Paper } from '@mui/material';
import { useRouter } from 'next/router';
import Main from 'layouts/Main';
import Form from './components/Form';
import { profileOptions } from 'utils/constants';

const LoginPage = () => {
  const router = useRouter();
  const { profile: profileQuery } = router.query;

  const profile = profileOptions.includes(profileQuery) ? profileQuery : null;

  const profileText = profile ? profile.toLowerCase() : 'Rejigg';

  return (
    <Main title="Log in | Rejigg" hideActions>
      <Container>
        <Box
          minHeight="calc(100vh - 200px)"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={420}
          margin="auto"
        >
          <Box sx={{ textAlign: 'center' }} marginBottom={2}>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {!profile ? 'Welcome to' : 'Rejigg'}{' '}
              <Typography
                component="span"
                color="primary.main"
                sx={{ fontWeight: 700, fontSize: 'inherit' }}
              >
                {profileText}
              </Typography>
            </Typography>

            <Typography
              sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
              color="text.secondary"
            >
              Log in
            </Typography>

            <Typography
              color="text.secondary"
              sx={{ pt: 1, fontWeight: 700, fontSize: 'inherit' }}
            >
              {profile === 'OWNER' && (
                <>
                  Not a Business Owner? Log in as a{' '}
                  <Link href="/account/login?profile=SEARCHER">Searcher</Link>{' '}
                  instead.
                </>
              )}
              {profile === 'SEARCHER' && (
                <>
                  Not a Searcher? Log in as an{' '}
                  <Link href="/account/login?profile=OWNER">Owner</Link>{' '}
                  instead.
                </>
              )}
            </Typography>
          </Box>

          <Paper sx={{ p: 4, mb: 2 }}>
            <Form appViewDefault={profile} />
          </Paper>
        </Box>
      </Container>
    </Main>
  );
};

export default LoginPage;
