import { rightDrawerWidth } from 'utils/constants';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useMediaQuery } from '@mui/material';

const getInitialRightDrawerWidth = () => {
  if (typeof window !== 'undefined') {
    return parseInt(
      localStorage.getItem('rightDrawerWidth') || rightDrawerWidth,
    );
  }
  return rightDrawerWidth;
};

const store = (set) => {
  return {
    isTopBarLoading: false,
    pageLoadingMessage: '',
    isRightDrawerOpen: false,
    isLeftDrawerOpen: true,
    rightDrawerWidth: getInitialRightDrawerWidth(),
    initialRightDrawerWidth: rightDrawerWidth,
    isSecondLeftDrawerOpen: false,

    setPageLoadingMessage: (message) => set({ pageLoadingMessage: message }),

    setIsRightDrawerOpen: (isOpen) => set({ isRightDrawerOpen: isOpen }),

    setIsLeftDrawerOpen: (isOpen) => set({ isLeftDrawerOpen: isOpen }),

    toggleLeftDrawer: () =>
      set((state) => ({ isLeftDrawerOpen: !state.isLeftDrawerOpen })),

    setRightDrawerWidth: (width) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem('rightDrawerWidth', width);
        set({ rightDrawerWidth: width });
      }
    },

    toggleSecondLeftDrawer: () => {
      set((state) => ({
        isSecondLeftDrawerOpen: !state.isSecondLeftDrawerOpen,
      }));
    },

    setIsSecondLeftDrawerOpen: (isOpen) =>
      set({ isSecondLeftDrawerOpen: isOpen }),

    withTopbarLoading:
      (fn) =>
      async (...args) => {
        set({ isTopBarLoading: true });
        try {
          const result = await fn(...args);
          set({ isTopBarLoading: false });
          return result;
        } catch (error) {
          set({ isTopBarLoading: false });
          throw error;
        }
      },
  };
};

const useLayoutStore = create(
  devtools(store, {
    name: 'layoutStore',
    enabled: process.env.NODE_ENV === 'development',
  }),
);

export default useLayoutStore;
